
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AccountModes } from '@/enums/login';

import { ModalHelper } from '@/helpers/modal-helper';

import { ToggleMySportsNotificationPayload } from '@/enums/sports';
import {
	ToggleMySportsNotificationDocument,
	ToggleMySportsNotificationMutation,
	ToggleMySportsNotificationMutationVariables,
} from '@/graphql/mutation/ToggleMySportsNotification.mutation';
import { AccountModal } from '@/lazy-load-components';

const Language = namespace('language');
const User = namespace('user');

@Component({
	name: 'ToggleMySportsNotificationMutationMixin',
})
export default class ToggleMySportsNotificationMutationMixin extends Vue {
	@Language.Getter locale: string;

	@User.Getter isLoggedIn: boolean;

	async toggleMySportsNotification({ eventId, isNotificationActive }: ToggleMySportsNotificationPayload) {
		if (!this.isLoggedIn) {
			ModalHelper.openModal(
				AccountModal,
				{
					closable: true,
					startScreen: AccountModes.SIGN_UP,
				},
				{ cssClass: 'login-modal' }
			);

			return;
		}

		this.toggleNotificationMutation(eventId, !isNotificationActive);
	}

	toggleNotificationMutation(id: string, state: boolean) {
		return this.$apollo.mutate<ToggleMySportsNotificationMutation, ToggleMySportsNotificationMutationVariables>({
			mutation: ToggleMySportsNotificationDocument,
			variables: {
				input: {
					id,
					state,
				},
			},
			refetchQueries: [
				'GetMySportsEvents',
				'GetSportEventV2',
				'GetCompetitionPageV2',
				'GetMultiStepSportOverview',
				'GetAllSportsPageV2',
			],
		});
	}
}
