import { SportLocale } from '@/enums/sports';
import type VueI18n from 'vue-i18n';

import { getOverviewUrlBySport } from '@/routing/sports';

import {
	ObjectType,
	SingleStepSportEvent,
	SportCompetitionStageName,
	SportCompetitionStageV2,
	SportCompetitionV2,
	SportEventAggregationKeyV2,
	SportType,
} from '@/@types/graphql-types';
import type { WebLocale } from '@/enums/web-locale';
import { SportPageType } from '@/interfaces/sport-graphql';

/////////////////
//* UTILITIES *//
/////////////////
export type SingleStepContext =
	| SingleStepOverviewContext
	| SingleStepCompetitionContext
	| SingleStepEventContext
	| SingleStepTeamContext;

export const CompetitorComposition = {
	Player: 'player',
	Team: 'team',
} as const;

type CompetitorComposition = (typeof CompetitorComposition)[keyof typeof CompetitorComposition];

export type FormattedCompetitor = {
	name: string;
	iconUrl?: string;
	score?: number;
	fullPath: string | undefined;
	sportCategory?: CompetitorComposition;
};
export type OrderedCompetitors = { start: FormattedCompetitor[]; end: FormattedCompetitor[]; isSwitched: boolean };

/**
 * Noop function to wrap translation keys in a way vue-i18n-extract can catch.
 * Supported formats: https://github.com/Spittal/vue-i18n-extract#supported-vue-i18n-formats
 */
export const $t = (key: string) => key;

////////////////////////////////
//* COMMON SINGLE STEP SPORT *//
///////////////////.////////////

/** Get the formatted  from the event. */
export function getCompetitorsParams(
	event: SingleStepSportEvent,
	mustInvertHomeAndAway = false,
	sportCategory: CompetitorComposition = CompetitorComposition.Team
): OrderedCompetitors {
	const homeContent = event.competitors.homeTeam.competitors[0].content;
	const homeTeam = {
		name: homeContent.name,
		iconUrl: homeContent.iconUrl ?? undefined,
		score: event.competitors.homeTeam.score ?? undefined,
		fullPath: homeContent.fullPath ?? undefined,
		sportCategory: sportCategory,
	};

	const awayContent = event.competitors.awayTeam.competitors[0].content;
	const awayTeam = {
		name: awayContent.name,
		iconUrl: awayContent.iconUrl ?? undefined,
		score: event.competitors.awayTeam.score ?? undefined,
		fullPath: awayContent.fullPath ?? undefined,
		sportCategory: sportCategory,
	};

	return mustInvertHomeAndAway
		? { start: [awayTeam], end: [homeTeam], isSwitched: mustInvertHomeAndAway }
		: { start: [homeTeam], end: [awayTeam], isSwitched: mustInvertHomeAndAway };
}

function getEventTitleParams(event: SingleStepSportEvent, mustInvertHomeAndAway = false) {
	const [homeTeam] = event.competitors?.homeTeam.competitors.map(c => c.content.name) ?? '';
	const [awayTeam] = event.competitors?.awayTeam.competitors.map(c => c.content.name) ?? '';

	const key = mustInvertHomeAndAway ? $t('SPORTS_WEBAPP_TEAM_AT') : $t('SPORTS_WEBAPP_TEAM_VS');

	return { key, params: { homeTeam, awayTeam } };
}

function getStageHeading(competitionStage: SportCompetitionStageV2, t: typeof VueI18n.prototype.t) {
	const { name, number: value = 1 } = competitionStage;
	if (name === SportCompetitionStageName.Undefined || name == null) return '';

	return t(CompetitionStageTranslations[name], { value }).toString();
}

//////////////////////////////////
//* SINGLE STEP SPORT OVERVIEW *//
//////////////////////////////////

interface BaseSingleStepOverviewContext {
	/**
	 * Translation key for the overview page's main title.
	 *
	 * @example
	 * ```html
	 * <h1>{{ $t(context.headline) }}</h1>
	 * ```
	 */
	headline?: string;

	/**
	 * Translation key for the main body of text on the overview page.
	 *
	 * @example
	 * ```html
	 * <h2>{{ $t(context.headline) }}</h2>
	 * ```
	 */
	subheading?: string;

	/**
	 * Translation key for the sport's name.
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.sportName) }}</p>
	 * ```
	 */
	sportName?: string;

	/**
	 * Translation key for the kind of sport event (Match or Race).
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.eventType) }}</p>
	 * ```
	 */
	eventType: string;

	/**
	 * Given a competition, get the translation key for it's heading.
	 *
	 * @example
	 * ```ts
	 * get heading(key: SportEventAggregationKeyV2) {
	 *     return this.context.getCompetitionHeading(bucket.key, this.$t.bind(this));
	 * }
	 * ```
	 */
	getCompetitionHeading: (key: SportEventAggregationKeyV2, t: typeof VueI18n.prototype.t) => string;

	/** Path to the sport's Icon */
	icon: string;

	/** The sport's overview page URL */
	getUrl: (locale: SportLocale) => string;

	/** Tracking page type. */
	pageType: ObjectType.Sport;

	/** The technical sport name. */
	sportType: SportType;

	/** Get all the parameters to create the event's title. */
	getEventTitleParams: (
		event: SingleStepSportEvent,
		mustInvertHomeAndAway?: boolean
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/**
	 * Get the event's title in the correct order and language.
	 *
	 * @example
	 * ```ts
	 * get getEventTitle(event: SingleStepSportEvent, webLocale: WebLocale) {
	 *     const { key, params } = this.context.getEventTitle(event, webLocale);
	 *     return this.$t(key, params);
	 * }
	 * ```
	 */
	getEventTitle?: (
		event: SingleStepSportEvent,
		webLocale: WebLocale
	) => { key: string; params: { homeTeam: string; awayTeam: string } };
}

export type SingleStepOverviewContext = Required<Omit<BaseSingleStepOverviewContext, 'getEventTitleParams'>> & {
	getCompetitors: (event: SingleStepSportEvent, webLocale: WebLocale) => OrderedCompetitors;
};

/**
 * Create the pieces of context common across all Sport Overview pages.
 * Using a module instead of a class to encourage composability over inheritance.
 *
 * Per sport implementations can then add or overide these functions as needed.
 */
export function singleStepOverview(sport: SportType): BaseSingleStepOverviewContext {
	function getCompetitionHeading(key: SportEventAggregationKeyV2, t: typeof VueI18n.prototype.t) {
		const heading = getStageHeading(key.competitionStage as SportCompetitionStageV2, t);
		const competitionName = key.competition?.content.name;

		if (heading === '') return competitionName ?? '';

		return `${competitionName} - ${heading}`;
	}

	return {
		/** TEXT */
		eventType: $t('SPORTS_WEBAPP_MATCHES'),
		getCompetitionHeading,

		/** MEDIA */
		icon: `/${ASSETS_DIR}/img/sports/icons/sport/${sport.toLowerCase()}.png`,

		/** TRACKING */
		getUrl: (locale: SportLocale) => getOverviewUrlBySport(sport, locale),
		pageType: SportPageType.SportOverview,

		/** MISC */
		sportType: sport,

		getEventTitleParams,
	};
}

/////////////////////////////////////
//* SINGLE STEP SPORT COMPETITION *//
/////////////////////////////////////

interface BaseSingleStepCompetitionContext {
	/**
	 * Translation key for the sport's name.
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.sportName) }}</p>
	 * ```
	 */
	sportName?: string;

	/**
	 * Translation key for the kind of sport event (Match or Race).
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.eventType) }}</p>
	 * ```
	 */
	eventType: string;

	/**
	 * Given a competition, get the translation key for it's stages.
	 *
	 * @example
	 * ```ts
	 * get stage(key: SportEventAggregationKeyV2) {
	 *     return this.context.getStageHeading(bucket.key, this.$t.bind(this));
	 * }
	 * ```
	 */
	getStageHeading: (competitionStage: SportCompetitionStageV2, t: typeof VueI18n.prototype.t) => string;

	/** Get all the parameters to create the event's title. */
	getEventTitleParams: (
		event: SingleStepSportEvent,
		mustInvertHomeAndAway?: boolean
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/**
	 * Get the event's title in the correct order and language.
	 *
	 * @example
	 * ```ts
	 * get getEventTitle(event: SingleStepSportEvent, webLocale: WebLocale) {
	 *     const { key, params } = this.context.getEventTitle(event, webLocale);
	 *     return this.$t(key, params);
	 * }
	 * ```
	 */
	getEventTitle?: (
		event: SingleStepSportEvent,
		webLocale: WebLocale
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/** Path to the sport's Icon */
	iconSrcset: (competition: SportCompetitionV2) => string[];

	/** The sport's overview page URL */
	getOverviewUrl: (locale: SportLocale) => string;

	/** Tracking page type. */
	pageType: ObjectType.SportCompetition;

	/** The technical sport name. */
	sportType: SportType;
}

export type SingleStepCompetitionContext = Required<Omit<BaseSingleStepCompetitionContext, 'getEventTitleParams'>> & {
	getCompetitors: (event: SingleStepSportEvent, webLocale: WebLocale) => OrderedCompetitors;
};

// note: images will come from backend soon and served through /images
const SPORTS_DIR = `https://www.${JW_CONFIG.DOMAIN}/sports`;

export function singleStepCompetition(sport: SportType): BaseSingleStepCompetitionContext {
	function iconSrcset(competition: SportCompetitionV2) {
		if (competition == null) return [];

		return [
			`${SPORTS_DIR}/competition/${competition.id}.png`,
			`${SPORTS_DIR}/competition/${competition.id}@2x.png 2x`,
		];
	}

	return {
		/** TEXT */
		eventType: $t('SPORTS_WEBAPP_MATCHES'),
		getStageHeading: (competitionStage: SportCompetitionStageV2, t: typeof VueI18n.prototype.t) => {
			return getStageHeading(competitionStage, t) || t('SPORTS_WEBAPP_MATCHES').toString();
		},

		/** MEDIA */
		iconSrcset,

		/** TRACKING */
		getOverviewUrl: (locale: SportLocale) => getOverviewUrlBySport(sport, locale),
		pageType: SportPageType.SportCompetition,

		/** MISC */
		sportType: sport,
		getEventTitleParams,
	} as const;
}

///////////////////////////////
//* SINGLE STEP SPORT EVENT *//
///////////////////////////////

interface BaseSingleStepEventContext {
	/**
	 * Translation key for the sport's name.
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.sportName) }}</p>
	 * ```
	 */
	sportName?: string;

	/**
	 * Translation key for the kind of sport event (Match or Race).
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.eventType) }}</p>
	 * ```
	 */
	eventType: string;

	/** Get all the parameters to create the event's title. */
	getEventTitleParams: (
		event: SingleStepSportEvent,
		mustInvertHomeAndAway?: boolean
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/**
	 * Get the event's title in the correct order and language.
	 *
	 * @example
	 * ```ts
	 * get getEventTitle(event: SingleStepSportEvent, webLocale: WebLocale) {
	 *     const { key, params } = this.context.getEventTitle(event, webLocale);
	 *     return this.$t(key, params);
	 * }
	 * ```
	 */
	getEventTitle?: (
		event: SingleStepSportEvent,
		webLocale: WebLocale
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/**
	 * Given a competition, get the translation key for it's stages.
	 *
	 * @example
	 * ```ts
	 * get stage(event: SingleStepSportEvent) {
	 *     return this.context.getStageHeading(event, this.$t.bind(this));
	 * }
	 * ```
	 */
	getStageHeading: (competitionStage: SportCompetitionStageV2, t: typeof VueI18n.prototype.t) => string;

	/** Tracking page type. */
	pageType: ObjectType.SportEvent;

	/** The technical sport name. */
	sportType: SportType;
}

export type SingleStepEventContext = Required<Omit<BaseSingleStepEventContext, 'getEventTitleParams'>> & {
	getCompetitors: (event: SingleStepSportEvent, webLocale: WebLocale) => OrderedCompetitors;
};

export function singleStepEvent(sport: SportType): BaseSingleStepEventContext {
	return {
		/** TEXT */
		eventType: $t('SPORTS_WEBAPP_MATCHES'),
		getEventTitleParams,
		getStageHeading,

		/** TRACKING */
		pageType: SportPageType.SportEvent,

		/** MISC */
		sportType: sport,
	};
}

///////////////////////////////
//* SINGLE STEP SPORT TEAM *//
/////////////////////////////

interface BaseSingleStepTeamContext {
	/**
	 * Translation key for the sport's name.
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.sportName) }}</p>
	 * ```
	 */
	sportName?: string;

	/**
	 * Translation key for the kind of sport event (Match or Race).
	 *
	 * @example
	 * ```html
	 * <p>{{ $t(context.eventType) }}</p>
	 * ```
	 */
	eventType: string;

	/** Get all the parameters to create the event's title. */
	getEventTitleParams: (
		event: SingleStepSportEvent,
		mustInvertHomeAndAway?: boolean
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/**
	 * Get the event's title in the correct order and language.
	 *
	 * @example
	 * ```ts
	 * get getEventTitle(event: SingleStepSportEvent, webLocale: WebLocale) {
	 *     const { key, params } = this.context.getEventTitle(event, webLocale);
	 *     return this.$t(key, params);
	 * }
	 * ```
	 */
	getEventTitle?: (
		event: SingleStepSportEvent,
		webLocale: WebLocale
	) => { key: string; params: { homeTeam: string; awayTeam: string } };

	/** The sport's overview page URL */
	getOverviewUrl: (locale: SportLocale) => string;

	/**
	 * Given a competition, get the translation key for it's stages.
	 *
	 * @example
	 * ```ts
	 * get stage(event: SingleStepSportEvent) {
	 *     return this.context.getStageHeading(event, this.$t.bind(this));
	 * }
	 * ```
	 */
	getStageHeading: (competitionStage: SportCompetitionStageV2, t: typeof VueI18n.prototype.t) => string;

	/** Tracking page type. */
	pageType: ObjectType.SportCompetitor;

	/** The technical sport name. */
	sportType: SportType;
}

export type SingleStepTeamContext = Required<Omit<BaseSingleStepTeamContext, 'getEventTitleParams'>> & {
	getCompetitors: (event: SingleStepSportEvent, webLocale: WebLocale) => OrderedCompetitors;
};

export function singleStepTeam(sport: SportType): BaseSingleStepTeamContext {
	return {
		/** TEXT */
		eventType: $t('SPORTS_WEBAPP_MATCHES'),
		getEventTitleParams,
		getStageHeading,

		/** TRACKING */
		getOverviewUrl: (locale: SportLocale) => getOverviewUrlBySport(sport, locale),
		pageType: SportPageType.SportTeam,

		/** MISC */
		sportType: sport,
	};
}

////////////////////
//* TRANSLATIONS *//
////////////////////

const CompetitionStageTranslations: Record<SportCompetitionStageName, string> = {
	/** All sports */
	[SportCompetitionStageName.Undefined]: $t('SPORTS_WEBAPP_UNDEFINED'),
	[SportCompetitionStageName.MatchDay]: $t('SPORTS_WEBAPP_MATCH_DAY'),

	/** "Standard" single-step */
	[SportCompetitionStageName.Final]: $t('SPORTS_WEBAPP_FINAL'),
	[SportCompetitionStageName.Semifinal]: $t('SPORTS_WEBAPP_SEMIFINAL'),
	[SportCompetitionStageName.Quarterfinal]: $t('SPORTS_WEBAPP_ROUND_OF_8'),
	[SportCompetitionStageName.RoundOf_8]: $t('SPORTS_WEBAPP_ROUND_OF_8'),
	[SportCompetitionStageName.RoundOf_16]: $t('SPORTS_WEBAPP_ROUND_OF_16'),
	[SportCompetitionStageName.RoundOf_32]: $t('SPORTS_WEBAPP_ROUND_OF_32'),
	[SportCompetitionStageName.RoundOf_64]: $t('SPORTS_WEBAPP_ROUND_OF_64'),
	[SportCompetitionStageName.RoundOf_128]: $t('SPORTS_WEBAPP_ROUND_OF_128'),
	[SportCompetitionStageName.QualificationFinal]: $t('SPORTS_WEBAPP_QUALIFICATION_FINAL'),
	[SportCompetitionStageName.QualificationRound_2]: $t('SPORTS_WEBAPP_QUALIFICATION_ROUND_2'),
	[SportCompetitionStageName.QualificationRound_1]: $t('SPORTS_WEBAPP_QUALIFICATION_ROUND_1'),
	[SportCompetitionStageName.Qualification]: $t('SPORTS_WEBAPP_QUALIFICATION'),
	[SportCompetitionStageName.PlayoffRound]: $t('SPORTS_WEBAPP_PLAYOFF_ROUND'),

	/** Formula racing */
	[SportCompetitionStageName.Race]: $t('SPORTS_WEBAPP_RACE'),
	[SportCompetitionStageName.Qualifying]: $t('SPORTS_WEBAPP_QUALIFYING'),
	[SportCompetitionStageName.Practice]: $t('SPORTS_WEBAPP_PRACTICE'),
	[SportCompetitionStageName.Sprint]: $t('SPORTS_WEBAPP_SPRINT'),

	/** American football */
	[SportCompetitionStageName.AmericanFootballConferenceChampionship]: $t(
		'SPORTS_WEBAPP_AMERICAN_FOOTBALL_CONFERENCE_CHAMPIONSHIP'
	),
	[SportCompetitionStageName.AmericanFootballConferenceDivisionalRound]: $t(
		'SPORTS_WEBAPP_AMERICAN_FOOTBALL_CONFERENCE_DIVISIONAL_ROUND'
	),
	[SportCompetitionStageName.AmericanFootballConferenceWildCardRound]: $t(
		'SPORTS_WEBAPP_AMERICAN_FOOTBALL_CONFERENCE_WILD_CARD_ROUND'
	),
	[SportCompetitionStageName.NationalFootballConferenceChampionship]: $t(
		'SPORTS_WEBAPP_NATIONAL_FOOTBALL_CONFERENCE_CHAMPIONSHIP'
	),
	[SportCompetitionStageName.NationalFootballConferenceDivisionalRound]: $t(
		'SPORTS_WEBAPP_NATIONAL_FOOTBALL_CONFERENCE_DIVISIONAL_ROUND'
	),
	[SportCompetitionStageName.NationalFootballConferenceWildCardRound]: $t(
		'SPORTS_WEBAPP_NATIONAL_FOOTBALL_CONFERENCE_WILD_CARD_ROUND'
	),
	[SportCompetitionStageName.SuperBowl]: $t('SPORTS_WEBAPP_SUPER_BOWL'),
} as const;
